import React from 'react'
import {Grid, IconButton, Paper, Typography} from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {Link} from "react-router-dom";


function Footer(){
    const scrollToServiceById = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
    }

    return <div style={{ backgroundColor: '#D5F2E3', height: '40vh' }} id={'footer'}>
        <Grid container justifyContent="center" style={{backgroundColor: '#D5F2E3'}}>
            <Grid item md={3} xs={12}>
                <Paper elevation={0} style={{ padding: '50px', backgroundColor: '#D5F2E3', marginLeft: '5%' }}>
                    <Typography variant={'h4'} style={{padding: '10px'}}>
                        <Link to={'/'} style={{textDecoration: 'none', color: '#01110A'}} onClick={() => window.scrollTo(0, 0)}>Klar Kurs AS</Link>
                        </Typography>
                    <Typography variant="h5" style={{ padding: '10px', textAlign: 'left'}}>
                        org.nr: 987 851 503
                    </Typography>
                    <Typography variant="h5" style={{ padding: '10px', textAlign: 'left'}}>
                        Telefon: + 47 952 20 369
                    </Typography>
                    <Typography variant="h5" style={{ padding: '10px', textAlign: 'left' }}>
                        E-post: rasmus@klarkurs.no
                    </Typography>
                    <div style={{ marginTop: '20px' }}>
                        <IconButton
                            aria-label="Linkedin"
                            href="https://www.linkedin.com/in/rasmuscl/"
                            target="_blank"
                            style={{ color: '#01110A' }}
                        >
                            <LinkedInIcon style={{ fontSize: '60px' }} />
                        </IconButton>
                    </div>
                </Paper>
            </Grid>
            <Grid item md={3} xs={12}>
                <Paper elevation={0} style={{ padding: '50px', backgroundColor: '#D5F2E3', marginLeft: '5%' }}>
                <Typography variant={'h4'} style={{padding: '10px'}}>
                    <Link to={'/visjon'} style={{textDecoration: 'none', color: '#01110A'}} onClick={() => window.scrollTo(0, 0)}>Visjon</Link>
                </Typography>
                </Paper>
            </Grid>
            <Grid item md={3} xs={12}>
                <Paper elevation={0} style={{ padding: '50px', backgroundColor: '#D5F2E3', marginLeft: '5%' }}>
                    <Typography variant={'h4'} style={{padding: '10px'}}>
                        <Link to={'/tjenester'} style={{textDecoration: 'none', color: '#01110A'}} onClick={() => window.scrollTo(0,0)}>Tjenester</Link>
                    </Typography>
                    <Typography variant={'h5'} style={{padding: '10px'}}>
                        <Link to={'/tjenester'} style={{textDecoration: 'none', color: '#01110A'}} onClick={() => scrollToServiceById('advice')}>Rådgivning</Link>
                    </Typography>
                    <Typography variant={'h5'} style={{padding: '10px'}}>
                        <Link to={'/tjenester'} style={{textDecoration: 'none', color: '#01110A'}} onClick={() => scrollToServiceById('recruiting')}>Rekruttering</Link>
                    </Typography>
                    <Typography variant={'h5'} style={{padding: '10px', color: '01110A'}}>
                        <Link to={'/tjenester'} style={{textDecoration: 'none', color: '#01110A'}} onClick={() => scrollToServiceById('rental')}>Utleie</Link>
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    </div>
}

export default Footer;