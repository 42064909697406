import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
// @ts-ignore
import Image from '../assets/portrett.png';
// @ts-ignore
import Logo from '../assets/logo.png';
// @ts-ignore
import Image1 from '../assets/example1.jpeg';
function Home() {

    const [isMobile, setIsMobile] = useState(false);
     useEffect(() => {
         const handleResize = () => {
             setIsMobile(window.innerWidth <= 768);
         };

         handleResize();
         window.addEventListener('resize', handleResize);
         return() => {
             window.removeEventListener('resize', handleResize);
         };
     }, [])


    //

    return (
           <div style={{height: '100vh'}} id={'home'}>
                <Grid container spacing={''} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={8}  marginTop={'15%'}>
                       <Typography variant={'h4'} style={{marginBottom: '4%'}}>Hei, velkommen til Klar Kurs</Typography>
                       <Typography variant="h5">
                        Vi deler vår praktisk orienterte kunnskap med ledere og eiere i små og mellomstore bedrifter. Samtaler, råd og coaching innen plan og strategi, personell, rekruttering, salg og styring av IT oppgaver.
                       </Typography>
                        <div style={{marginTop: '5%'}}>
                             <button className={'more-info-btn'}>
                                Jeg vil vite mer!
                             </button>
                        </div>
                   </Grid>
               </Grid>
           </div>
    );
}

export default Home;
