import React, {useEffect, useState} from 'react';
import {Typography} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';


interface ContactFormProps {
    onSubmit: (formData: FormData) => void;
    mailSuggestion: string | null;
}
interface FormData {
    senderName: string;
    senderEmail: string;
    senderContent: string;
}
const ContactForm : React.FC<ContactFormProps> = ({ onSubmit, mailSuggestion }) => {

    const serviceId = 'service_mgezg0t';
    const templateId = 'template_23zhjrt';

    const [formData, setFormData] = useState<FormData>(
        {
            senderName: '',
            senderEmail: '',
            senderContent: ''
    });

    useEffect(() => {
        if (mailSuggestion !== null) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                senderContent: mailSuggestion,
            }));
        }
    }, [mailSuggestion]);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e : React.FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
        console.log(formData);
        setFormData({ senderName: '', senderEmail: '', senderContent: ''});
    }

    const sendEmail = () => {

    }


    return <form onSubmit={handleSubmit} id={'contact-form'} style={{height: '30vh', width: '50%', padding: '8%', border: '3px solid #01110A', borderRadius: '10px'}}>
        <Typography variant={'h4'} marginBottom={'20px'}>Send meg en mail</Typography>
            <div >
                <input style={{height: '30px', width: '100%', fontSize: '20px', border: '2px solid lightgrey', padding: '5px', borderRadius: '5px'}}  type={'text'} placeholder={'Ditt navn'} id={'senderName'} name={'senderName'} value={formData.senderName} onChange={handleInputChange} required />
            </div>
            <div>
                <input style={{height: '30px', width: '100%', marginTop: '10px', marginBottom: '10px', fontSize: '20px', border: '2px solid lightgrey', padding: '5px', borderRadius: '5px'}} type={'text'} id={'senderEmail'} placeholder={'Din e-mail'} name={'senderEmail'} value={formData.senderEmail} onChange={handleInputChange} required />
            </div>
            <div>
                <textarea style={{width: '100%', marginTop: '10px', marginBottom: '10px', fontSize: '20px', border: '2px solid lightgrey', padding: '5px', borderRadius: '5px'}} id={'senderContent'} name={'senderContent'} value={formData.senderContent} onChange={handleInputChange} rows={4} required />
            </div>
        <button className={'send-btn'} type={"submit"}>
            Send
            <SendIcon style={{ marginLeft: '15px' }} />
        </button>
    </form>
}

export default ContactForm;