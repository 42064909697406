import React, {useState} from 'react';
import {Grid, Typography} from "@mui/material";
import '../style.css';
// @ts-ignore
import Image from "../assets/portrett.png";
import ContactForm from "../components/ContactForm";
function Services() {

    const [mail, setMail] = useState('');
    const fillInForm = (mail: string) => {
        const contactForm = document.getElementById('form'); // Use the actual ID of your Footer component
        if (contactForm) {
            contactForm.scrollIntoView({ behavior: 'smooth' });
        }
        setMail(mail);
    }

    const sendEmail = () => {

    }

    return (
        <div style={{height: 'auto'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} marginTop={'5%'} marginLeft={'8%'} marginBottom={'5%'}>
                    <Typography variant="h4" marginBottom={'2%'} id={'advice'}>
                        Rådgivning
                    </Typography>
                    <Typography variant="h6">
                    </Typography>
                    <br></br>
                    <span onClick={() => fillInForm("Jeg er interessert i å avtale et møte som handøer om Rådgivning for min bedrift.")} style={{cursor: 'pointer', textDecoration: 'none', fontSize: '20px'}} className={'nav-link'}>
                        Vil du vite mer om min rådgivning?
                    </span>
                    <Typography variant="h4" marginTop={'5%'} id={'recruiting'}>
                        Om rekruttering
                    </Typography>
                    <Typography variant="h6" marginTop={'2%'}>
                    </Typography>
                    <br></br>
                    <span onClick={() => fillInForm("Jeg er interessert i å avtale et møte som handler om rekruttering")} style={{cursor: 'pointer', textDecoration: 'none', fontSize: '20px'}} className={'nav-link'}>
                        Vil du vite mer om rekruttering i din bedrift?
                    </span>
                    <Typography variant="h4" marginTop={'5%'} id={'rental'}>
                        Utleie
                    </Typography>
                    <Typography variant="h6" marginTop={'2%'}  id={'form'}>
                    </Typography>
                    <br></br>
                    <span onClick={() => fillInForm("Jeg er interessert i å få vite mer om utleie")} style={{cursor: 'pointer', textDecoration: 'none', fontSize: '20px'}} className={'nav-link'}>
                        Vil du vite mer om utleie?
                    </span>
                </Grid>
                <Grid item xs={12} md={12} marginLeft={'8%'} marginBottom={'5%'}>
                    <ContactForm onSubmit={sendEmail} mailSuggestion={mail} />
                </Grid>
            </Grid>
        </div>
    );
}

export default Services;

