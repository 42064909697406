import React from 'react';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Vision from "./pages/vision";
import Services from "./pages/services";
import Home from "./pages/home";
import Footer from "./components/Footer";

function App() {
  return (
      <div>
      <Router>
          <div style={{backgroundColor: '#ffffff', fontFamily: 'sans-serif'}}>
              <Navbar />
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/visjon" element={<Vision />} />
                  <Route path="/tjenester" element={<Services />} />
              </Routes>
          </div>
          <Footer />
      </Router>
      </div>
  );
}

export default App;
