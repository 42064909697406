import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import { Grid, Typography} from "@mui/material";
import '../style.css'
// @ts-ignore
import Logo from '../assets/logo.png'

function Navbar() {

    const currentLocation = useLocation();
    const isLinkActive = (path: string) => {
        return currentLocation.pathname === path ? 'active-link' : 'nav-link';
    }

    const scrollToFooter = () => {
        const footerElement = document.getElementById('footer');
        if (footerElement) {
            footerElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
            <Grid container spacing={1} style={{backgroundColor: '#f5f5f5', height: '10vh'}}>
                {!isMobile ? (
                    <><Grid item md={2}></Grid><Grid item md={2} style={{marginTop: '20px'}}>
                        <Typography variant="h4" style={{textAlign: 'center'}}>
                            <Link to="/" style={{textDecoration: 'none'}} className={isLinkActive('/')}>
                                Klar Kurs
                            </Link>
                        </Typography>
                    </Grid><Grid item md={2} style={{marginTop: '20px'}}>
                        <Typography variant="h4" style={{textAlign: 'center'}}>
                            <Link to="/visjon" style={{textDecoration: 'none'}} className={isLinkActive('/visjon')}>
                                Visjon
                            </Link>
                        </Typography>
                    </Grid><Grid item md={2} style={{marginTop: '20px'}}>
                        <Typography variant="h4" style={{textAlign: 'center'}}>
                            <Link to="/tjenester" style={{textDecoration: 'none'}}
                                  className={isLinkActive('/tjenester')}>
                                Tjenester
                            </Link>
                        </Typography>
                    </Grid><Grid item md={2} style={{marginTop: '20px'}}>
                        <Typography variant="h4" style={{textAlign: 'center'}}>
                            <span onClick={scrollToFooter} style={{cursor: 'pointer'}} className={'nav-link'}>
                                Kontakt
                            </span>
                        </Typography>
                    </Grid><Grid item md={2}></Grid></>
                ) : (<div>Burger menu should appear fakkers</div>)
                }
            </Grid>
    );
}

export default Navbar;
