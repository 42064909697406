import React from 'react';
import {Grid, Typography} from "@mui/material";

function Vision() {
    return (
        <div style={{height: '100vh'}} id={'vision'}>
            <Grid container spacing={''} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={8}  marginTop={'15%'}>
                    <Typography variant={'h2'} align={'center'} style={{marginBottom: '4%'}}>Klar Kurs sin visjon</Typography>
                    <Typography variant="h5" align={'center'}>
                        Ideer og drømmer det vi lever for. Det er juicen i livet, å spille spillet og å vinne små og store seirer på veien. Kunsten er å vinne flere ganger enn du taper, og å ikke gjøre noen store feilvurderinger.

                        En god plan er en kombinasjon av å observere, evaluere, planlegge, ta avgjørelser og sette ut i livet. En del av denne prosessen er å kunne konfrontere den virkelige situasjonen og hva den krever. For å lykkes må du være smart, ha korrekt kunnskap og være villig til å gjennomføre. Det kan være utfordrende og det kan kreve offervilje.

                        Gjennomføringen og suksessen til en god plan krever både dristighet og stødig gjennomføring, den er en kombinasjon av store og små handlinger som alle går i samme retning og bidrar til oppnåelsen av dine og bedriften sine mål.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default Vision;
